import React, { useMemo } from "react";
import { css } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import type { Meta } from "@/__main__/router.mjs";
import gamesList from "@/app/games.mjs";
import { hasCMSAccess } from "@/feature-cms/utils.mjs";
import ArticlePreview from "@/feature-news/ArticlePreview.jsx";
import Container from "@/shared/ContentContainer.jsx";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function ArticleManager(): React.JSX.Element {
  const { searchParams } = useRoute();
  const environment = useMemo(
    () => (hasCMSAccess(searchParams) ? "staging" : "production"),
    [searchParams],
  );
  const {
    news: { latest },
  } = useSnapshot(readState);
  const games = useMemo(
    () => Object.entries(latest[environment] ?? {}),
    [environment, latest],
  );
  return (
    <Container className="flex column gap-6">
      <div className={cssArticleManager()}>
        {games.map(([game, struct]) => {
          const g = gamesList.find((g) => g.shortName === game);
          if (!g) return null;
          const { gameSymbol, fullName } = g;
          return (
            <div key={game} className="flex column">
              <div className="type-h3">{fullName[1]}</div>
              <div
                className="flex gap-2 items"
                style={{
                  // These are the exact values from the original article preview implementation
                  "--article-tile-width": "15rem",
                  "--article-tile-max-width": "26rem",
                }}
              >
                {Array.isArray(struct) ? (
                  struct.map((_, idx) => (
                    <ArticlePreview key={idx} game={gameSymbol} index={idx} />
                  ))
                ) : (
                  <ArticlePreview game={gameSymbol} />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </Container>
  );
}

// Not required, this is an admin-only feature for @blitz.gg accounts
export function meta(): Meta {
  return {
    title: ["", ""],
    description: ["", ""],
  };
}

const cssArticleManager = () => css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--sp-10);
  .items > div {
    min-width: var(--article-tile-width);
    max-width: var(--article-tile-max-width);
  }
  [class^="type-h"] {
    text-transform: capitalize;
  }
`;
